import React, { Component } from 'react';
import './ReportHeader.scss';
import Button from 'react-bootstrap/Button';

export default class ReportHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.onClickR=this.onClickR.bind(this);
        this.onClickS=this.onClickS.bind(this);
        this.onClickF=this.onClickF.bind(this);
        this.onClickW=this.onClickW.bind(this);
    }

    render() {

        var variantR = 'outline-primary';
        var variantS = 'outline-primary';
        switch (this.props.pageId) {
            case 'R':
                variantR = "primary";
                break;
            case 'S':
                variantS = "primary";
                break;
            default:
        }


        return (
            <div className="ReportHeader">
                <div className="rh rh-left">
                    <Button size="sm" variant={variantR} onClick={this.onClickR}>
                    {this.props.package.json.translations.report_screen.leaderboard !== undefined &&
                        <>{this.props.package.json.translations.report_screen.leaderboard}</>}
                    {this.props.package.json.translations.report_screen.leaderboard === undefined &&
                        <> Ranking </>}
                    </Button>

                    
                    <Button size="sm" variant={variantS} onClick={this.onClickS}>
                    {this.props.package.json.translations.report_screen.details !== undefined &&
                        <>{this.props.package.json.translations.report_screen.details}</>}
                    {this.props.package.json.translations.report_screen.details === undefined &&
                        <>Szczegóły</>}                        
                    </Button>
                    
                </div>
                <div className="rh rh-middle">
                </div>
                <div className="rh rh-right">
                    <Button size="sm" variant="outline-secondary" onClick={this.onClickF} disabled={this.props.isRefreshDisabled}>
                    {this.props.package.json.translations.report_screen.refresh !== undefined &&
                        <>{this.props.package.json.translations.report_screen.refresh}</>}
                    {this.props.package.json.translations.report_screen.refresh === undefined &&
                        <>Odśwież</>}
                    </Button>
                                        
                    <Button size="sm" variant="outline-secondary" onClick={this.onClickW}>
                    {this.props.package.json.translations.report_screen.clear !== undefined &&
                        <>{this.props.package.json.translations.report_screen.clear}</>}
                    {this.props.package.json.translations.report_screen.clear === undefined &&
                        <>Wyczyść</>}                        
                    </Button>

                </div>
            </div>
        )
    }

    onClickR() {
        this.props.onButtonClickCallback('R');
    }
    onClickS() {
        this.props.onButtonClickCallback('S');
    }
    onClickF() {
        this.props.onButtonClickCallback('F');
    }
    onClickW() {
        this.props.onButtonClickCallback('W');
    }

}
